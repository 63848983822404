/* eslint-disable operator-linebreak */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import GMapsAutocomplete from '../Autocomplete/GMapsAutocomplete';
import CouncilSelect from './CouncilSelect';
import DatePicker from '../DatePicker';
import { BinSelect } from '../core';
import {
  API_BIN,
  BIN_TYPES,
  RESOURCE_BINS,
  RESOURCE_COUNCIL_PERMITS,
  WASTE_TYPES_TEXT,
  DIMENSIONS,
  PRICE,
} from '../../redux/types';
import { bookingUpdate as _bookingUpdate } from '../../redux/actions/booking';
import { fetchResource as _fetchResource } from '../../redux/actions/resources';
import { getResource } from '../../redux/selectors/resources';
import { getBooking } from '../../redux/selectors/booking';
import { useAlert, useInput, useSelect } from '../../utils/hooks';
import { nextBusinessDay } from '../../utils/date';
import hookBinImg from '../../assets/img/hook-bin-dimensions.png';
import marrellBinImg from '../../assets/img/marrell-bin-dimensions.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const getLocationData = _.memoize((address, suburb, state, postcode, coordinates) => ({
  address,
  suburb,
  state,
  postcode,
  coordinates,
}));

const BookingForm = ({ bins, booking, bookingUpdate, fetchResource, handlePrev, handleNext }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const [binId, setBinId] = useSelect(booking.binId);
  const [dimensions, setDimensions] = React.useState([]);
  const [wasteType, setWasteType] = React.useState(booking.wasteType || '');
  const mattresses = useInput(booking.mattresses || 0);
  const tyres = useInput(booking.tyres || 0);
  const specialInstruction = useInput(booking.specialInstruction);
  const duration = useInput(booking.duration);
  const [isSameDayDelivery, setIsSameDayDelivery] = React.useState(false);
  const deliveryTime = useInput(booking.deliveryTime || '');
  const deliveryDate = useInput(
    booking.deliveryDate ? moment(booking.deliveryDate).format('YYYY-MM-DD') : nextBusinessDay().format('YYYY-MM-DD')
  );
  const canSubmit =
    !_.isNil(binId) &&
    !_.isEmpty(wasteType) &&
    !_.isEmpty(deliveryDate.value) &&
    !_.isEmpty(booking.address) &&
    !_.isEmpty(deliveryTime.value);

  const handleChangeWasteType = useCallback(
    (e) => {
      const { value } = e.target;
      setWasteType(value);
      setBinId('');
    },
    [setWasteType] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangeBin = useCallback(
    (newBinId) => {
      setBinId(newBinId);
    },
    [setBinId]
  );

  const handleChange = () => {
    const bin = _.get(bins, binId);
    const delivery = moment(deliveryDate.value);
    const pickup = moment(delivery);
    pickup.add(duration.value, 'days');

    bookingUpdate({
      binId: bin?.id,
      binType: bin?.type || '',
      mattresses: mattresses.value,
      tyres: tyres.value,
      specialInstruction: specialInstruction.value,
      wasteType,
      duration: duration.value,
      deliveryDate: delivery.format('YYYY-MM-DD'),
      deliveryTime: deliveryTime.value,
      pickupDate: pickup.format('YYYY-MM-DD'),
    });
  };

  const handleClick = () => {
    handleChange();
    handleNext();
  };

  const onAddressChange = (locationData) => bookingUpdate(locationData);
  const onSameDayDelivery = useAlert();

  const locationData = getLocationData(
    booking.address,
    booking.suburb,
    booking.state,
    booking.postcode,
    booking.coordinates
  );

  React.useEffect(() => {
    handleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    binId,
    deliveryDate.value,
    duration.value,
    mattresses.value,
    tyres.value,
    specialInstruction.value,
    wasteType,
    deliveryTime.value,
  ]);

  React.useEffect(() => {
    const getData = async () => {
      await Promise.all([
        fetchResource(RESOURCE_BINS, { path: API_BIN, params: { show: 1000 } }),
        fetchResource(RESOURCE_COUNCIL_PERMITS, { path: `/${RESOURCE_COUNCIL_PERMITS}`, params: { show: 1000 } }),
      ]);
      setLoading(false);
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const currentDimension = DIMENSIONS[_.get(bins, `[${binId}].volume`)];
    setDimensions(_.isEmpty(currentDimension) ? [] : [..._.values(currentDimension)]);
  }, [bins, binId]);

  let binImage = null;
  switch (_.get(bins, `[${binId}].type`)) {
    case BIN_TYPES.HOOK:
      binImage = hookBinImg;
      break;
    case BIN_TYPES.MARRELL:
      binImage = marrellBinImg;
      break;
    default:
      binImage = marrellBinImg;
      break;
  }

  return loading ? (
    <LinearProgress />
  ) : (
    <React.Fragment>
      <GMapsAutocomplete locationData={locationData} onChange={onAddressChange} />
      <TextField
        select
        fullWidth
        margin="normal"
        label="Waste Type"
        variant="standard"
        value={wasteType}
        onChange={handleChangeWasteType}
      >
        {_.map(WASTE_TYPES_TEXT, (label, id) => (
          <MenuItem disabled={bins[binId]?.price[id] === 0} key={id} value={id}>
            {label.title}
          </MenuItem>
        ))}
      </TextField>
      {!_.isEmpty(wasteType) ? (
        <ul>
          {WASTE_TYPES_TEXT[wasteType].notes.map((n) => (
            <li>{n}</li>
          ))}
        </ul>
      ) : null}
      <BinSelect
        isDisabled={_.isEmpty(booking.wasteType)}
        selectedBinId={binId}
        wasteType={wasteType}
        onChange={handleChangeBin}
      />
      {binImage && (
        <div style={{ marginBottom: '1rem' }}>
          <img src={binImage} alt="Bin dimension" style={{ width: '100%' }} />
          {_.isEmpty(dimensions) ? (
            "There's no detail dimensions on this bin"
          ) : (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Length</TableCell>
                  <TableCell align="center">Width</TableCell>
                  <TableCell align="center">Height</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {_.values(DIMENSIONS[_.get(bins, `[${binId}].volume`)]).map((dimension) => (
                    <TableCell key={dimension} align="center">{`${dimension} mm`}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            label={`Mattresses - $${PRICE.MATTRESS}`}
            type="number"
            fullWidth
            margin="normal"
            inputProps={{ min: 0 }}
            {...mattresses}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={`Tyres - $${PRICE.TYRE}`}
            type="number"
            fullWidth
            margin="normal"
            inputProps={{ min: 0 }}
            {...tyres}
          />
        </Grid>
      </Grid>
      <TextField
        label="Duration"
        inputProps={{
          type: 'number',
          default: 3,
          min: 3,
        }}
        fullWidth
        margin="normal"
        helperText="3 days is the minimum estimation"
        {...duration}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isSameDayDelivery}
            onChange={() => setIsSameDayDelivery((v) => !v)}
            name="checkedB"
            color="primary"
          />
        }
        label="Sameday delivery"
      />
      {isSameDayDelivery &&
        onSameDayDelivery({
          text: 'Please call 0401 336 010 if you want to book the bin for same day delivery.',
          severity: 'error',
        })}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              label="Delivery Date"
              value={deliveryDate.value}
              onChange={(date) => deliveryDate.onChange({ target: { value: date } })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="deliveryTimeLabel">Delivery Time</InputLabel>
            <Select
              labelId="deliveryTimeLabel"
              id="deliveryTime"
              fullWidth
              disabled={isSameDayDelivery}
              {...deliveryTime}
            >
              <MenuItem value="pm">PM</MenuItem>
              <MenuItem value="am">AM</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <CouncilSelect />

      <TextField
        label="Special Instruction"
        placeholder="E.g. Site Manager contact, Purchase Order, Delivery Address"
        fullWidth
        multiline
        rows={4}
        margin="normal"
        InputLabelProps={{ shrink: true }}
        {...specialInstruction}
      />
      <Grid container justify="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={handlePrev}>
            Prev
          </Button>
        </Grid>
        <Grid item>
          <Button disabled={!canSubmit} variant="contained" color="primary" onClick={handleClick}>
            Next
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

BookingForm.propTypes = {
  bins: PropTypes.object,
  booking: PropTypes.object,
  bookingUpdate: PropTypes.func.isRequired,
  fetchResource: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  bins: getResource(state, RESOURCE_BINS),
  booking: getBooking(state),
});

export default connect(mapStateToProps, {
  bookingUpdate: _bookingUpdate,
  fetchResource: _fetchResource,
})(BookingForm);
