import moment from 'moment';

const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
const DATE_FORMAT = 'DD/MM/YYYY';

export const formatDateTime = (datetimeStr) => moment(datetimeStr).format(DATE_TIME_FORMAT);

export const getDisplayDateToFuture = (date1, date2) => moment(date1).to(date2);

export const formatDate = (datetimeStr) => moment(datetimeStr).format(DATE_FORMAT);

/**
 * get the next business day based on a given date
 *
 * @param {string|Object} date
 * @returns {Object}
 */
export const nextBusinessDay = (date = moment()) => {
  let dayIncrement = 1;
  const momentDate = moment(date);

  if (momentDate.day() === 5) {
    // set to monday
    dayIncrement = 3;
  } else if (momentDate.day() === 6) {
    // set to monday
    dayIncrement = 2;
  }

  return momentDate.add(dayIncrement, 'days');
};
