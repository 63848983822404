import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { getBooking } from '../../redux/selectors/booking';
import { getResource } from '../../redux/selectors/resources';
import { bookingUpdate as _bookingUpdate } from '../../redux/actions/booking';
import { BIN_PLACEMENT, BIN_PLACEMENT_TEXT, RESOURCE_COUNCIL_PERMITS } from '../../redux/types';

const CouncilSelect = ({ booking, councilPermits, bookingUpdate }) => {
  const updateBinPlacement = (event) => bookingUpdate({ binPlacement: event.target.value, councilPermitId: null });

  const updateCouncilPermitId = (event) => bookingUpdate({ councilPermitId: event.target.value });

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          margin="normal"
          label="Where to place the bin?"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          value={booking?.binPlacement}
          onChange={updateBinPlacement}
        >
          {_.map(BIN_PLACEMENT_TEXT, (label, id) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {booking.binPlacement === BIN_PLACEMENT.PUBLIC && (
        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            margin="normal"
            label="Select City Council"
            variant="standard"
            value={booking?.councilPermitId || ''}
            onChange={updateCouncilPermitId}
          >
            {_.map(councilPermits, ({ name }, id) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );
};

CouncilSelect.propTypes = {
  booking: PropTypes.object,
  councilPermits: PropTypes.object,
  bookingUpdate: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  booking: getBooking(state),
  councilPermits: getResource(state, RESOURCE_COUNCIL_PERMITS),
});

export default connect(mapStateToProps, { bookingUpdate: _bookingUpdate })(CouncilSelect);
