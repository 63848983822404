/* eslint-disable no-confusing-arrow */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getResource } from '../../redux/selectors/resources';
import { RESOURCE_BINS, WASTE_TYPES } from '../../redux/types';

const BinSelect = ({ isDisabled, shouldDisplayType, bins, wasteType, error, selectedBinId, onChange }) => {
  const selectedBin = selectedBinId ? bins[selectedBinId] : null;

  return (
    <Autocomplete
      disableClearable
      disabled={isDisabled}
      value={selectedBin}
      options={_.values(bins)}
      getOptionSelected={(opt, value) => _.isEqual(opt.id, value?.id)}
      getOptionDisabled={(opt) => opt?.price[wasteType] === 0}
      renderOption={(opt) =>
        opt ? (
          <span>
            {shouldDisplayType ? `${opt.type} - ${opt.volume}` : opt.volume}m<sup>3</sup> -
            {opt.price[wasteType] === 0 ? ' No Service' : ` $${opt.price[wasteType]} - 3 days hire`}
          </span>
        ) : (
          ''
        )
      }
      getOptionLabel={(opt) => {
        let typeText = '';
        if (shouldDisplayType) {
          typeText = `${opt?.type} - ${opt?.volume}` || '';
        } else {
          typeText = `${opt?.volume}m3` || '';
        }

        return `${typeText} - $${opt?.price[wasteType]} - 3 days hire`;
      }}
      onChange={(event, newValue) => onChange(newValue.id)}
      renderInput={(params) => (
        <TextField error={!!error} helperText={error} fullWidth {...params} label="Bin Volume" />
      )}
    />
  );
};

BinSelect.propTypes = {
  isDisabled: PropTypes.bool,
  shouldDisplayType: PropTypes.bool,
  bins: PropTypes.object,
  error: PropTypes.string,
  selectedBinId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  wasteType: PropTypes.string.isRequired,
};

BinSelect.defaultProps = {
  isDisabled: false,
  shouldDisplayType: false,
  wasteType: WASTE_TYPES.HOUSEHOLD,
};

const mapStateToProps = (state) => ({
  bins: getResource(state, RESOURCE_BINS),
});

export default connect(mapStateToProps)(BinSelect);
