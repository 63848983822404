/* eslint-disable indent, operator-linebreak */
import _ from 'lodash';
import swal from 'sweetalert';
import { RESOURCE_REMOVE, RESOURCE_UPDATE } from '../types';
import fetchAPI from '../../utils/fetchAPI';

/**
 * @param {string} resourceName
 * @param {object} resource
 */
export const updateResource = (resourceName, resource) => ({
  type: RESOURCE_UPDATE,
  data: resource,
  resourceName,
});

export const fetchResource = (resourceName, opt) => async (dispatch) => {
  const { path, params = {} } = opt;
  const result = await fetchAPI('GET', { path, params });
  if (_.get(result, 'error')) {
    return swal({
      title: 'Error fetching data',
      text: _.get(result.error, 'response.data.message', 'Unknown error.'),
      icon: 'error',
    });
  }

  const data = result.rows ? result.rows : [result];
  return dispatch(updateResource(resourceName, data));
};

export const postResource = (resourceName, opt) => async (dispatch) => {
  const { path, data = {}, params = {}, options } = opt;
  const result = await fetchAPI('POST', { path, data, params, options });
  if (result?.error !== undefined) {
    return swal({
      title: 'Error creating data',
      text: _.get(result.error, 'response.data.message', 'Unknown error.'),
      icon: 'error',
    });
  }
  return dispatch(updateResource(resourceName, result));
};

export const patchResource = (resourceName, opt) => async (dispatch) => {
  const { path, data = {}, params = {} } = opt;
  const result = await fetchAPI('PUT', { path, data, params });
  if (result?.error !== undefined) {
    return swal({
      title: 'Error updating data',
      text: _.get(result.error, 'response.data.message', 'Unknown error.'),
      icon: 'error',
    });
  }

  await swal({
    title: 'Success updating data',
    icon: 'success',
  });
  return dispatch(updateResource(resourceName, result));
};

export const deleteResource =
  (resourceName, id, uri = null) =>
  async (dispatch) => {
    const path = _.isNull(uri) ? `/${resourceName}/${id}` : uri;
    const result = await fetchAPI('DELETE', { path });
    if (result?.error !== undefined) {
      return swal({
        title: 'Error deleting data',
        text: _.get(result.error, 'response.data.message', 'Unknown error.'),
        icon: 'error',
      });
    }
    return dispatch({ type: RESOURCE_REMOVE, resourceName, id });
  };
