import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchResource as _fetchResource } from '../../redux/actions/resources';
import { getResource } from '../../redux/selectors/resources';
import { RESOURCE_POSTCODES } from '../../redux/types';

const PostcodeForm = ({ handleNext, fetchPostcodes, postcodes }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [postcodeOptions, setPostcodeOptions] = React.useState([]);
  const [postcode, setPostcode] = React.useState(null);
  const canSubmit = !_.isEmpty(postcode);

  React.useEffect(() => {
    const getData = async () => {
      await fetchPostcodes(RESOURCE_POSTCODES, { path: `/${RESOURCE_POSTCODES}`, params: { show: 500 } });
      setIsLoading(false);
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // map postcodes into react-select list
    const options = _.map(_.values(postcodes), (item) => ({
      value: item.postcode,
      label: item.postcode,
    }));
    setPostcodeOptions(options);
  }, [postcodes]);

  return (
    <React.Fragment>
      <div style={{ height: 500 }}>
        <Autocomplete
          loading={isLoading}
          disabled={isLoading}
          onChange={(__, item = null) => setPostcode(item?.value)}
          options={postcodeOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label="Please input your postcode" />}
          noOptionsText={'Service is not available at your area'}
          fullWidth
        />
      </div>

      <Grid container justify="space-between">
        <Grid item />
        <Grid item>
          <Button disabled={!canSubmit} variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

PostcodeForm.propTypes = {
  postcodes: PropTypes.object,
  fetchPostcodes: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  postcodes: getResource(state, RESOURCE_POSTCODES),
});

export default connect(mapStateToProps, { fetchPostcodes: _fetchResource })(PostcodeForm);
