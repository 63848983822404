export const CURRENT_USER_UPDATE = 'CURRENTUSER_UPDATE';
export const RESOURCE_UPDATE = 'RESOURCE_UPDATE';
export const RESOURCE_REMOVE = 'RESOURCE_REMOVE';
export const RESOURCE_USERS = 'users';
export const RESOURCE_JOBS = 'jobs';
export const RESOURCE_BINS = 'bins';
export const RESOURCE_TRUCKS = 'trucks';
export const RESOURCE_BIN_PRICING = 'binPricing';
export const RESOURCE_BOOKINGS = 'bookings';
export const RESOURCE_SYSTEM_SETTINGS = 'system-settings';
export const RESOURCE_COUNCIL_PERMITS = 'council-permits';
export const RESOURCE_POSTCODES = 'postcodes';
export const API_JOBS = '/jobs';
export const API_BOOKING = '/booking';
export const API_BIN = '/bin';
export const API_ROLE = 'role';
export const API_ROLES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  DRIVER: 'driver',
};

export const API_ROLES_TEXT = {
  [API_ROLES.ADMIN]: 'Admin',
  [API_ROLES.CUSTOMER]: 'Customer',
  [API_ROLES.DRIVER]: 'Driver',
};

export const BIN_TYPES = {
  HOOK: 'hook',
  MARRELL: 'marrell',
};

export const WASTE_TYPES = {
  HOUSEHOLD: 'household',
  CONSTRUCTION: 'construction',
  CONSTRUCTION_WITH_SOIL: 'construction with soil',
};

export const WASTE_TYPES_TEXT = {
  [WASTE_TYPES.HOUSEHOLD]: {
    title: 'Household & Light Construction',
    notes: [
      'No Asbestos or other hazardous waste',
      'No cleanfill/hardfill',
      'No Sand, Soil, Clay or Dirt',
      'No food products or food waste',
      'No mattresses and tyres',
    ],
  },
  [WASTE_TYPES.CONSTRUCTION]: {
    title: 'Construction - Mixed heavy (No Soil)',
    notes: [
      'No Asbestos or other hazardous waste',
      'No Sand, Soil, Clay or Dirt',
      'No food products or food waste',
      'No mattresses and tyres',
    ],
  },
  [WASTE_TYPES.CONSTRUCTION_WITH_SOIL]: {
    title: 'Construction - Mixed heavy',
    notes: ['No Asbestos or other hazardous waste', 'No food products or food waste', 'No mattresses and tyres'],
  },
};

export const BIN_PLACEMENT = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const BIN_PLACEMENT_TEXT = {
  [BIN_PLACEMENT.PUBLIC]: 'Place on public land',
  [BIN_PLACEMENT.PRIVATE]: 'Place on your property',
};

export const JOBS_TYPE = {
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
};

export const JOB_STATUS = {
  ACTIVE: 'active',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

export const JOB_PAYMENT_STATUS = {
  NA: 'not-applicable',
  PENDING: 'pending',
  PAID: 'paid',
};

export const JOB_STATUS_TEXT = {
  [JOB_STATUS.ACTIVE]: 'Active',
  [JOB_STATUS.IN_PROGRESS]: 'In Progress',
  [JOB_STATUS.COMPLETED]: 'Completed',
  [JOB_STATUS.ARCHIVED]: 'Archived',
};

export const BOOKING = 'booking';
export const BOOKING_RESET = 'BOOKING_RESET';
export const BOOKING_PATCH = 'BOOKING_PATCH';
export const PAYMENT_METHOD = {
  CASH: 'cash',
  PAYMENT_GATEWAY: 'gateway',
  DEFERRED: 'deferred', // option for existing customer
};

export const SYSTEM_CONFIG_KEYS = {
  QB_TOKEN: 'quickbooks-token',
};

export const BOOKING_STATUS = {
  PENDING_PAYMENT: 'pending-payment',
  PAID: 'paid',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

export const PRICE = {
  TYRE: 15,
  MATTRESS: 25,
};

export const GST = 0.1;

export const DEFAULT_MAP_CENTER = {
  lat: -37.8088858,
  lng: 144.9640968,
};

export const DIMENSIONS = {
  3: {
    length: 2500,
    width: 1600,
    height: 950,
  },
  4: {
    length: 3300,
    width: 1600,
    height: 950,
  },
  5: {
    length: 3400,
    width: 1600,
    height: 1300,
  },
  6: {
    length: 3700,
    width: 1600,
    height: 1300,
  },
  8: {
    length: 3700,
    width: 1600,
    height: 1650,
  },
  10: {
    length: 4600,
    width: 2200,
    height: 1400,
  },
  12: {
    length: 4600,
    width: 2200,
    height: 1650,
  },
  15: {
    length: 4900,
    width: 2200,
    height: 1850,
  },
};
