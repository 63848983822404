import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';

const renderAddressText = (booking) => {
  const { address, suburb, state, postcode } = booking;
  return `${address}, ${suburb}, ${state} ${postcode}`;
};

const PreviewCustomer = ({ booking }) => (
  <Fragment>
    <TextField label="Name" defaultValue={booking.name} fullWidth margin="normal" disabled />
    <TextField label="Email" defaultValue={booking.email} fullWidth margin="normal" disabled />
    <TextField label="Phone" defaultValue={booking.phone} fullWidth margin="normal" disabled />
    <TextField
      required
      label="Address"
      fullWidth
      multiline
      rows={3}
      margin="normal"
      defaultValue={renderAddressText(booking)}
      disabled
    />
  </Fragment>
);

PreviewCustomer.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default PreviewCustomer;
