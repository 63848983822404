import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { getResource } from '../../redux/selectors/resources';
import { fetchResource as _fetchResource, patchResource as _patchResource } from '../../redux/actions/resources';
import { API_ROLES, RESOURCE_TRUCKS, RESOURCE_USERS } from '../../redux/types';
import { useSelect } from '../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const NumberPlate = ({ user, trucks, redirectUri, fetchResource, patchResource }) => {
  const classes = useStyles();
  const history = useHistory();
  const [truckId, setTruckId] = useSelect(_.get(user, 'truckId') || '');
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getData = async () => {
      if (_.get(user, 'role') === API_ROLES.DRIVER) {
        await fetchResource(RESOURCE_TRUCKS, { path: `/${RESOURCE_TRUCKS}` });
        setIsLoading(false);
      }
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (_.get(user, 'role') !== API_ROLES.DRIVER) return null;

  const updateTruck = async () => {
    if (truckId !== user.truckId) {
      await patchResource(RESOURCE_USERS, { path: `/${RESOURCE_USERS}/${user.id}`, data: { truckId } });
    }

    history.replace(redirectUri);
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel shrink id="number-plate-label">
            Number plate
          </InputLabel>
          <Select fullWidth labelId="number-plate-label" value={truckId} onChange={setTruckId}>
            {_.map(trucks, ({ id, numberPlate }) => (
              <MenuItem key={id} value={id}>
                {numberPlate}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          disabled={!truckId}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={updateTruck}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

NumberPlate.propTypes = {
  user: PropTypes.object.isRequired,
  trucks: PropTypes.object,
  redirectUri: PropTypes.object,
  fetchResource: PropTypes.func.isRequired,
  patchResource: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  trucks: getResource(state, RESOURCE_TRUCKS),
});

export default connect(mapStateToProps, { fetchResource: _fetchResource, patchResource: _patchResource })(NumberPlate);
