import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CreditCard from '@material-ui/icons/CreditCard';
import AccessTime from '@material-ui/icons/AccessTime';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { bookingPost } from '../../redux/actions/booking';
import { getBooking } from '../../redux/selectors/booking';
import { getCurrentUser } from '../../redux/selectors/currentUserId';
import { PAYMENT_METHOD } from '../../redux/types';
import fetchAPI from '../../utils/fetchAPI';
import { isCustomer } from '../../utils/authentication';
import Preview from '../../components/BookingForm/Preview';

const { REACT_APP_FILES_URL } = process.env;

const useStyles = makeStyles((theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

const BookingPayment = ({ currentUser, booking, pushBooking }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTermsChecked, setIsTermsChecked] = React.useState(false);
  const isAnonymous = !isCustomer(currentUser);

  const handleDeferPayment = async () => {
    const result = await pushBooking({
      ...booking,
      paymentMethod: PAYMENT_METHOD.DEFERRED,
    });
    if (result.error) {
      swal({
        title: 'Error create booking',
        text: result.error.response.statusText,
        icon: 'error',
      });
    } else {
      swal({
        title: 'Booking created successfully',
        icon: 'success',
      });
      if (isAnonymous) {
        history.replace('/booking?success=true');
      } else {
        history.push('/admin/tracking');
      }
    }
  };

  const handlePaymentGateway = async () => {
    setIsLoading(true);
    const [stripe, data] = await Promise.all([
      loadStripe(process.env.REACT_APP_STRIPE_TOKEN),
      pushBooking({
        ...booking,
        paymentMethod: PAYMENT_METHOD.PAYMENT_GATEWAY,
      }),
    ]);
    if (data.error) {
      setIsLoading(false);
      return swal({
        title: 'Error create booking',
        text: data.error.response.statusText,
        icon: 'error',
      });
    }

    // get session id and redirect to payment
    const sessionId = await fetchAPI('GET', {
      path: `/booking/${data.id}/stripe-session-id`,
    });
    if (sessionId.error) {
      setIsLoading(false);
      return swal({
        title: 'Error connecting to Stripe. Please contact the administrator.',
        text: sessionId.error.response.statusText,
        icon: 'error',
      });
    }

    const result = await stripe.redirectToCheckout({ sessionId });
    if (result.error) {
      setIsLoading(false);
      return swal({
        title: 'Error create booking',
        text: result.error.message,
        icon: 'error',
      });
    }

    setIsLoading(false);
    return swal({
      title: 'Booking created successfully.',
      icon: 'success',
    });
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Grid container>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      )}

      <Preview />

      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={isTermsChecked} onChange={(event) => setIsTermsChecked(event.target.checked)} />}
          label={
            <div>
              I have read and agree to the&nbsp;
              <a href={`${REACT_APP_FILES_URL}Bill Bin Hire - Terms & Conditions.pdf`} target="_blank" rel="noreferrer">
                Terms and Conditions
              </a>
              &nbsp; of Bill Bin Hire.
            </div>
          }
        />
      </FormGroup>

      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid item xs={!isAnonymous ? 6 : 12}>
          <Button
            disabled={isLoading || !isTermsChecked}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<CreditCard />}
            onClick={handlePaymentGateway}
            className={classes.button}
            fullWidth
          >
            Pay with Card
          </Button>
        </Grid>
        {!isAnonymous && (
          <Grid item xs={6}>
            <Button
              disabled={isLoading || !isTermsChecked}
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<AccessTime />}
              onClick={handleDeferPayment}
              className={classes.button}
              fullWidth
            >
              Defer Payment
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

BookingPayment.propTypes = {
  currentUser: PropTypes.object,
  booking: PropTypes.object,
  pushBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  booking: getBooking(state),
});

const mapDispatchToProps = (dispatch) => ({
  pushBooking: (opt) => dispatch(bookingPost(opt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPayment);
