import PropTypes from 'prop-types';
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  formLabel: {
    margin: 0,
    marginTop: theme.spacing(3),
  },
}));

const CustomerDetailsForm = ({ isDisabled, name, email, phone, onChange }) => {
  const classes = useStyles();

  const changeHandler = (field) => (event) => onChange(field, event.target.value);

  return (
    <FormControl className={classes.formControl}>
      <FormLabel className={classes.formLabel} component="legend">
        Contact Detail
      </FormLabel>
      <TextField
        disabled={isDisabled}
        fullWidth
        label="Full Name / Company Name"
        margin="normal"
        value={name}
        onChange={changeHandler('name')}
      />
      <TextField
        disabled={isDisabled}
        fullWidth
        label="Email"
        margin="normal"
        type="email"
        value={email}
        onChange={changeHandler('email')}
      />
      <TextField
        disabled={isDisabled}
        fullWidth
        label="Phone"
        margin="normal"
        type="tel"
        value={phone}
        placeholder="0412456789"
        onChange={changeHandler('phone')}
        required
      />
    </FormControl>
  );
};

CustomerDetailsForm.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CustomerDetailsForm.defaultProps = {
  isDisabled: false,
  name: '',
  email: '',
  phone: '',
};

export default CustomerDetailsForm;
