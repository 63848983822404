import _ from 'lodash';
import { createSelector } from 'reselect';

export const getResources = (state) => state.resources;

export const getResource = createSelector(
  getResources,
  (state, name) => name,
  (resources, name) => resources[name]
);

export const getResourceBy = createSelector(
  getResource,
  (state, resources, opt) => opt,
  (resources, opt) => {
    const { key, value } = opt;
    const data = _.filter(Object.values(resources), (item) => {
      let result = false;
      if (_.isArray(value)) {
        result = value.find((val) => _.isEqual(item[key], val));
      } else {
        result = _.isEqual(item[key], value);
      }
      return result;
    });
    return _.keyBy(data, 'id');
  }
);

export const getTotalJobByStatus = (state) => {
  const total = _.groupBy(state.resources, 'status');
  return total;
};
