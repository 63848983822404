import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { bookingUpdate as _bookingUpdate } from '../../redux/actions/booking';
import { getBooking } from '../../redux/selectors/booking';
import { getCurrentUser } from '../../redux/selectors/currentUserId';
import { useInput } from '../../utils/hooks';
import { isCustomer } from '../../utils/authentication';
import CustomerDetailsForm from '../CustomerDetailsForm';

const CustomerInfoForm = ({ booking, currentUser, bookingUpdate, handlePrev, handleNext }) => {
  const name = useInput(booking.name || _.get(currentUser, 'name'));
  const email = useInput(booking.email || _.get(currentUser, 'email'));
  const phone = useInput(booking.phone || _.get(currentUser, 'phone'));
  const isAnonymous = !isCustomer(currentUser);
  const canSubmit = !(_.isEmpty(name.value) || _.isEmpty(email.value) || _.isEmpty(phone.value));

  const onCustomerDetailsChange = (field, value) => {
    switch (field) {
      case 'name':
        name.onChange({ target: { value } });
        break;
      case 'email':
        email.onChange({ target: { value } });
        break;
      case 'phone':
        phone.onChange({ target: { value } });
        break;
      default:
        break;
    }
  };

  const handleClick = async () => {
    await bookingUpdate({
      name: name.value,
      email: email.value,
      phone: phone.value,
      ...(isAnonymous ? {} : { customerId: currentUser.id }),
    });
    handleNext();
  };

  return (
    <React.Fragment>
      <CustomerDetailsForm
        isDisabled={!isAnonymous}
        name={name.value}
        email={email.value}
        phone={phone.value}
        onChange={onCustomerDetailsChange}
      />

      <Grid container justify="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={handlePrev}>
            Prev
          </Button>
        </Grid>
        <Grid item>
          <Button disabled={!canSubmit} variant="contained" color="primary" onClick={handleClick}>
            Next
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

CustomerInfoForm.propTypes = {
  booking: PropTypes.object,
  bookingUpdate: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: getBooking(state),
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps, { bookingUpdate: _bookingUpdate })(CustomerInfoForm);
