import { BIN_PLACEMENT, BOOKING_RESET, BOOKING_PATCH } from '../types';

const initialState = {
  binId: '',
  address: '',
  suburb: '',
  postcode: null,
  state: '',
  phone: '',
  name: '',
  email: '',
  wasteType: '',
  binPlacement: BIN_PLACEMENT.PRIVATE,
  councilPermitId: null,
  mattresses: 0,
  tyres: 0,
  duration: 3,
  binType: '',
  specialInstruction: '',
  deliveryDate: '',
  pickupDate: '',
  coordinates: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_PATCH:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKING_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
