import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

// disable weekends
const disableWeekends = (date) => _.includes([5, 6], date.getUTCDay());

const useStyles = makeStyles({
  datePickerContainer: {
    width: '100%',
  },
});

const DatePicker = ({ label, value, onChange }) => {
  const classes = useStyles();

  const changeHandler = React.useCallback(
    (date) => {
      onChange(moment(date).format('YYYY-MM-DD'));
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        className={classes.datePickerContainer}
        variant="inline"
        value={value}
        onChange={changeHandler}
        format="dd/MM/yyyy"
        label={label}
        minDate={moment().add(1, 'days').format('YYYY-MM-DD')}
        shouldDisableDate={disableWeekends}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
