import _ from 'lodash';
import { currentUserIdUpdate } from '../redux/actions/currentUserId';
import { RESOURCE_USERS, API_ROLES } from '../redux/types';
import { updateResource } from '../redux/actions/resources';
import fetchAPI from './fetchAPI';

/**
 * @param {string} email
 * @param {string} password
 */
export const login = async (email, password) => {
  const data = await fetchAPI('POST', {
    path: '/auth/login',
    data: { email, password },
  });
  if (data?.error !== undefined) {
    throw data;
  }

  return data;
};

export const fetchCurrentUser = () => async (dispatch) => {
  const user = await fetchAPI('GET', {
    path: '/auth/current-user',
  });
  if (user?.error === undefined) {
    dispatch(currentUserIdUpdate(user.id));
    dispatch(updateResource(RESOURCE_USERS, user));
  } else {
    throw user;
  }
};

export const logout = () => async (dispatch) => {
  await fetchAPI('POST', {
    path: '/auth/logout',
  });
  dispatch(currentUserIdUpdate(null));
};

export const isCustomer = (currentUser) => _.get(currentUser, 'role') === API_ROLES.CUSTOMER;
