import { createSelector } from 'reselect';

import { RESOURCE_USERS } from '../types';
import { getResource } from './resources';

export const getCurrentUserId = (state) => state.currentUserId;

export const getCurrentUser = createSelector(
  getCurrentUserId,
  (state) => getResource(state, RESOURCE_USERS),
  (id, users) => users[id]
);
