import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { RESOURCE_BINS } from '../../../redux/types';
import { getResource } from '../../../redux/selectors/resources';

const PreviewBooking = ({ booking, bins }) => {
  const bin = bins[booking.binId];
  if (_.isEmpty(bin)) return null;
  return (
    <Fragment>
      <TextField
        label="Bin"
        margin="normal"
        fullWidth
        defaultValue={`type: ${bin.type}, volume: ${bin.volume}`}
        disabled
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            label="Mattresses"
            type="number"
            fullWidth
            margin="normal"
            defaultValue={booking.mattresses}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Tyres" type="number" fullWidth margin="normal" defaultValue={booking.tyres} disabled />
        </Grid>
      </Grid>
      <TextField
        label="Special Instruction"
        type="number"
        fullWidth
        multiline
        rows={4}
        margin="normal"
        defaultValue={booking.specialInstruction}
        disabled
      />
    </Fragment>
  );
};

PreviewBooking.propTypes = {
  booking: PropTypes.object.isRequired,
  bins: PropTypes.object,
};

const mapStateToProps = (state) => ({
  bins: getResource(state, RESOURCE_BINS),
});

export default connect(mapStateToProps)(PreviewBooking);
