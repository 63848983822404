import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Copyright from '../../components/Copyright';
import NumberPlate from './NumberPlate';
import { fetchCurrentUser as _fetchCurrentUser, login } from '../../utils/authentication';
import { API_ROLES } from '../../redux/types';
import { useInput } from '../../utils/hooks';
import logo from '../../assets/img/BillBin.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/collection/4520112/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '50%',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ fetchCurrentUser }) => {
  const classes = useStyles();
  const email = useInput('');
  const password = useInput('');
  const [loading, setLoading] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loggedInUser, setLoggedInUser] = React.useState(null);

  const history = useHistory();
  const { location } = history;
  const { from } = location.state || { from: { pathname: '/admin/dashboard' } };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = await login(email.value, password.value);
      setLoggedInUser(user);
      if (user.role !== API_ROLES.DRIVER) {
        history.replace(from);
      }
    } catch (err) {
      swal({
        title: 'Unauthorization',
        text: 'Incorrect email or password',
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCurrentUser();
        setLoading(false);
        history.push(from);
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        setLoading(false);
      }
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container component="main" className={classes.root}>
      {loading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <img src={logo} alt="Bill Bin logo" />

              {loggedInUser ? (
                <NumberPlate user={loggedInUser} redirectUri={from} />
              ) : (
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    {...email}
                  />
                  <FormControl fullWidth required margin="normal" variant="outlined">
                    <InputLabel htmlFor="password-field">Password</InputLabel>
                    <OutlinedInput
                      id="password-field"
                      labelWidth={80}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...password}
                    />
                  </FormControl>
                  <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                    Sign In
                  </Button>
                </form>
              )}

              <Box mt={5}>
                <Copyright />
              </Box>
            </div>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

Login.propTypes = {
  fetchCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { fetchCurrentUser: _fetchCurrentUser })(Login);
