import { combineReducers } from 'redux';

import currentUserId from './currentUserId';
import resources from './resources';
import booking from './booking';

const rootReducer = combineReducers({
  currentUserId,
  resources,
  booking,
});

export default rootReducer;
