import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBooking } from '../../redux/selectors/booking';
import PreviewCustomer from './Preview/PreviewCustomer';
import PreviewBooking from './Preview/PreviewBooking';
import PreviewShipping from './Preview/PreviewShipping';

const Preview = ({ booking }) => (
  <Fragment>
    <PreviewCustomer booking={booking} />
    <PreviewBooking booking={booking} />
    <PreviewShipping booking={booking} />
  </Fragment>
);

Preview.propTypes = {
  booking: PropTypes.object,
};

const mapStateToProps = (state) => ({
  booking: getBooking(state),
});

export default connect(mapStateToProps)(Preview);
