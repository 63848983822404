export const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

/**
 * converts a cent value to dollar value
 * e.g. 5050 => 50.50
 *
 * @param {number} amount
 * @return {number}
 */
export const toDollarValue = (amount) => amount / 100;
