import { CURRENT_USER_UPDATE } from '../types';

export default (state = null, action) => {
  switch (action.type) {
    case CURRENT_USER_UPDATE:
      return action.data;
    default:
      return state;
  }
};
