import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import swal from 'sweetalert';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SelectOption from './SelectOption';

const { REACT_APP_GMAPS_TOKEN } = process.env;

const ADDRESS_CATEGORIES = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code'];

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const GMapsAutocomplete = ({ locationData, onChange, isDisabled = false }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [geocoder, setGeocoder] = React.useState(null);
  const [autocompleteService, setAutocompleteService] = React.useState(null);
  const [isGoogleLoaded, setIsGoogleLoaded] = React.useState(false);
  const googleMaps = React.useRef();

  // ensure that the google library is loaded in window object
  // this is a cheat to run the effect every time inputValue is changed, but actually execute only when
  // window.google is present
  React.useEffect(() => {
    if (geocoder && autocompleteService) return;

    if (_.get(window, 'google.maps')) {
      setGeocoder(new window.google.maps.Geocoder());
      if (_.get(window, 'google.maps.places')) {
        setAutocompleteService(new window.google.maps.places.AutocompleteService());
      }
    }
  }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof window !== 'undefined' && !isGoogleLoaded) {
    if (!document.querySelector('#googleapis-script')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GMAPS_TOKEN}&libraries=places&lang=en`,
        document.querySelector('head'),
        'googleapis-script'
      );
    }

    setIsGoogleLoaded(true);
  }

  const getPlacePredictions = React.useMemo(
    () =>
      _.throttle((request, callback) => {
        if (autocompleteService) {
          autocompleteService.getPlacePredictions(request, callback);
        }
      }, 200),
    [autocompleteService]
  );

  const inputChangeHandler = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (newInputValue === '') {
      setOptions(_.compact([value]));
      return;
    }

    getPlacePredictions({ input: newInputValue, componentRestrictions: { country: 'au' } }, (results) =>
      setOptions(_.compact([value, ...results]))
    );
  };

  const selectHandler = (event, newValue) => {
    // save data
    geocoder.geocode({ placeId: newValue.place_id }, (resp) => {
      // eslint-disable-next-line camelcase
      const { address_components, geometry } = resp[0];
      const result = {};
      _.forEach(ADDRESS_CATEGORIES, (category) => {
        const component = _.find(address_components, (comp) => _.includes(comp.types, category));
        result[category] = _.get(component, 'long_name', '');
      });
      const { lat, lng } = geometry.location;

      // error checking
      const dataToUpdate = {
        address: _.compact([result.street_number, result.route]).join(' '),
        suburb: result.locality,
        state: result.administrative_area_level_1,
        postcode: +result.postal_code,
        coordinates: [lat(), lng()],
      };

      if (_(dataToUpdate).pickBy().keys().value().length < 5) {
        swal({
          title: 'Error getting address',
          text: 'Invalid selection, please try again',
          icon: 'error',
        });
        setValue(null);
        return;
      }

      setValue(newValue);
      onChange(dataToUpdate);
    });
  };

  React.useEffect(() => {
    if (!_.isEmpty(locationData?.coordinates)) {
      const position = {
        lat: locationData.coordinates[0],
        lng: locationData.coordinates[1],
      };
      const map = new window.google.maps.Map(googleMaps.current, {
        zoom: 15,
        center: position,
        disableDefaultUI: true,
      });
      // eslint-disable-next-line no-new
      new window.google.maps.Marker({ position, map });
    }
  }, [locationData]);

  return (
    <Fragment>
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        inputValue={inputValue}
        fullWidth
        onChange={selectHandler}
        onInputChange={inputChangeHandler}
        getOptionSelected={(option, selectedValue) => option.place_id === selectedValue.place_id}
        renderInput={(params) => <TextField {...params} margin="normal" label="Address" fullWidth />}
        renderOption={(option) => <SelectOption option={option} />}
        disabled={isDisabled}
      />
      {!_.isEmpty(locationData?.coordinates) && (
        <Box my={2}>
          <div id="google-maps" style={{ minHeight: 200 }} ref={googleMaps} />
        </Box>
      )}
    </Fragment>
  );
};

GMapsAutocomplete.propTypes = {
  locationData: PropTypes.shape({
    address: PropTypes.string,
    suburb: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.number,
    coordinates: PropTypes.arrayOf(PropTypes.number),
  }),
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default GMapsAutocomplete;
