/* eslint-disable */
/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-enable */
import React from 'react';
import AddBox from '@material-ui/icons/AddBox';
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import LocationOn from '@material-ui/icons/LocationOn';
import People from '@material-ui/icons/People';
import Commute from '@material-ui/icons/Commute';
import Kitchen from '@material-ui/icons/Kitchen';
import Work from '@material-ui/icons/Work';
import MapIcon from '@material-ui/icons/Map';
import Settings from '@material-ui/icons/Settings';
import TimeToLeave from '@material-ui/icons/TimeToLeave';
import LocationCity from '@material-ui/icons/LocationCity';
import { API_ROLES } from './redux/types';

// core components/views for Admin layout
const AdminUserList = React.lazy(() => import('./views/User/AdminUserList'));
const DashboardPage = React.lazy(() => import('./views/Dashboard/Dashboard'));
const UserProfile = React.lazy(() => import('./views/UserProfile/UserProfile'));
const CustomerList = React.lazy(() => import('./views/User/CustomerList'));
const DriverList = React.lazy(() => import('./views/User/DriverList'));
const Bin = React.lazy(() => import('./views/Bin/Bin'));
const Job = React.lazy(() => import('./views/Job/Job'));
const JobMap = React.lazy(() => import('./views/Job/JobMap'));
const Tracking = React.lazy(() => import('./views/Tracking/Tracking'));
const SystemSettings = React.lazy(() => import('./views/SystemSettings'));
const Booking = React.lazy(() => import('./views/Booking/Booking'));
const Truck = React.lazy(() => import('./views/Truck'));
const CouncilPermit = React.lazy(() => import('./views/CouncilPermit'));
const Postcode = React.lazy(() => import('./views/Postcode'));

const { ADMIN, CUSTOMER, DRIVER } = API_ROLES;

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
    roles: [ADMIN, DRIVER],
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: Person,
    component: UserProfile,
    layout: '/admin',
    exact: true,
    roles: [ADMIN, CUSTOMER, DRIVER],
    invisible: true,
  },
  {
    path: '/users',
    name: 'User List',
    icon: Person,
    component: AdminUserList,
    layout: '/admin',
    roles: [ADMIN],
  },
  {
    path: '/customers',
    name: 'Customer List',
    icon: People,
    component: CustomerList,
    layout: '/admin',
    roles: [ADMIN],
  },
  {
    path: '/customers/:userId/bins',
    name: 'Customer BIN List',
    icon: Kitchen,
    component: Bin,
    layout: '/admin',
    roles: [ADMIN],
    invisible: true,
  },
  {
    path: '/drivers',
    name: 'Driver List',
    icon: Commute,
    component: DriverList,
    layout: '/admin',
    roles: [ADMIN],
  },
  {
    path: '/jobs',
    name: 'Job List',
    icon: Work,
    component: Job,
    layout: '/admin',
    roles: [ADMIN, DRIVER],
  },
  {
    path: '/job/map',
    name: 'Jobs Location',
    icon: LocationOn,
    component: JobMap,
    layout: '/admin',
    roles: [ADMIN, DRIVER],
  },
  {
    path: '/bins',
    name: 'BIN List',
    icon: Kitchen,
    component: Bin,
    layout: '/admin',
    roles: [ADMIN, CUSTOMER],
  },
  {
    path: '/tracking',
    name: 'Bookings',
    icon: MapIcon,
    component: Tracking,
    layout: '/admin',
    roles: [ADMIN, CUSTOMER],
  },
  {
    path: '/trucks',
    name: 'Truck',
    icon: TimeToLeave,
    component: Truck,
    layout: '/admin',
    roles: [ADMIN],
  },
  {
    path: '/council-permits',
    name: 'Council Permit',
    icon: TimeToLeave,
    component: CouncilPermit,
    layout: '/admin',
    roles: [ADMIN],
  },
  {
    path: '/postcodes',
    name: 'Postcode',
    icon: LocationCity,
    component: Postcode,
    layout: '/admin',
    roles: [ADMIN],
  },
  {
    path: '/booking',
    name: 'New Booking',
    icon: AddBox,
    component: Booking,
    layout: '/admin',
    roles: [CUSTOMER],
  },
  {
    path: '/config',
    name: 'System Configuration',
    icon: Settings,
    component: SystemSettings,
    layout: '/admin',
    roles: [ADMIN],
  },
];

export default dashboardRoutes;
