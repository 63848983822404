import _ from 'lodash';
import { RESOURCE_UPDATE, RESOURCE_REMOVE, RESOURCE_SYSTEM_SETTINGS } from '../types';

const initialState = {
  users: {},
  bins: {},
  jobs: {},
  bookings: {},
  binPricing: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESOURCE_UPDATE:
      if (_.isArray(action.data)) {
        if (action.resourceName === RESOURCE_SYSTEM_SETTINGS) {
          // system settings we'll key by `key` instead
          return {
            ...state,
            [RESOURCE_SYSTEM_SETTINGS]: {
              ...state[RESOURCE_SYSTEM_SETTINGS],
              ..._.keyBy(action.data, 'key'),
            },
          };
        }

        return {
          ...state,
          [action.resourceName]: {
            ...state[action.resourceName],
            ..._.keyBy(action.data, 'id'),
          },
        };
      }
      return {
        ...state,
        [action.resourceName]: {
          ...state[action.resourceName],
          [action.data.id]: action.data,
        },
      };

    case RESOURCE_REMOVE:
      return {
        ...state,
        [action.resourceName]: _.omit(state[action.resourceName], [action.id]),
      };

    default:
      return state;
  }
};
