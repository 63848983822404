import swal from 'sweetalert';

import { API_BOOKING, BOOKING_PATCH, RESOURCE_BOOKINGS } from '../types';

import fetchAPI from '../../utils/fetchAPI';
import { updateResource } from './resources';

export const bookingUpdate = (opt) => ({
  type: BOOKING_PATCH,
  payload: opt,
});

export const bookingPost = (opt) => async (dispatch) => {
  try {
    const data = await fetchAPI('POST', {
      path: API_BOOKING,
      data: { ...opt },
    });

    dispatch(updateResource(RESOURCE_BOOKINGS, data));
    return data;
  } catch (e) {
    swal({
      title: 'Error create new data',
      text: e,
      icon: 'error',
    });
    throw e;
  }
};
