/* eslint-disable indent, operator-linebreak */
import _ from 'lodash';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

/**
 * @param {object} initialState
 * @returns {object}
 */
export const useInput = (initialState) => {
  const [value, setValue] = React.useState(initialState);
  const onChange = ({ target }) => {
    let val = null;
    switch (target.type) {
      case 'checkbox':
        val = target.checked;
        break;
      case 'file':
        [val] = target.files;
        break;
      default:
        val = target.value;
    }
    setValue(val);
  };

  return { value, onChange };
};

export const useSelect = (initialState) => {
  const [value, setValue] = React.useState(initialState);
  const onChange = (event) => {
    if (_.isObject(event) && !_.isPlainObject(event)) {
      setValue(event.target.value);
    } else {
      setValue(event);
    }
  };

  return [value, onChange];
};

/**
 * track which props trigger rendering
 * should only be used locally
 *
 * @param {Object} props
 * @param {string} label
 */
export const useTraceUpdate = (props, label = 'Component') => {
  const prev = React.useRef(props);
  React.useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      const newPs = _.cloneDeep(ps);
      if (prev.current[k] !== v) {
        newPs[k] = [prev.current[k], v];
      }
      return newPs;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // eslint-disable-next-line no-console
      console.log(`${label}: updated due to`, changedProps);
    }
    prev.current = props;
  });
};

/**
 * @name useDialog
 * @param {String} title
 * @returns {[Function, React.Component]}
 */
export const useDialog = (title) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDialog = () => setIsOpen((v) => !v);

  /**
   * @name dialogWrapper
   * @param {React.Component} component
   * @param {('lg'|'md'|'sm'|'xl'|'xs'|false)} maxWidth
   * @returns {React.Component}
   */
  const dialogWrapper = (component, maxWidth = 'md') => (
    <Dialog open={isOpen} onClose={() => toggleDialog()} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      {component}
    </Dialog>
  );

  return [toggleDialog, dialogWrapper];
};

export const useAlert =
  () =>
  ({ text, severity = 'standard' }) =>
    <Alert severity={severity}>{text}</Alert>;
