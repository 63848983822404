import _ from 'lodash';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Switch, Redirect, Route, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Navbar from '../components/Navbars/Navbar';
import Footer from '../components/Footer/Footer';
import Sidebar from '../components/Sidebar/Sidebar';
import routes from '../routes';
import styles from '../assets/jss/material-dashboard-react/layouts/adminStyle';
import { getCurrentUser } from '../redux/selectors/currentUserId';
import { fetchCurrentUser as _fetchCurrentUser } from '../utils/authentication';

import bgImage from '../assets/img/sidebar-2.jpg';
import logo from '../assets/img/BillBin.png';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const switchRoutes = (user) => (
  <React.Suspense fallback={<LinearProgress />}>
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === '/admin') {
          if (_.isEmpty(prop?.roles)) {
            return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
          }
          if (prop.roles.includes(user?.role)) {
            return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
          }
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  </React.Suspense>
);

const useStyles = makeStyles(styles);

const Admin = ({ ...rest }) => {
  const props = { ...rest };
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const [mainPanel, setMainPanel] = React.useState(null); // eslint-disable-line no-unused-vars
  const [ps, setPs] = React.useState(null);
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState('blue');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  const { user } = props;

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const getRoute = () => window.location.pathname !== '/admin/maps';

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await props.fetchCurrentUser();
        setLoading(false);
      } catch (e) {
        history.push('/login');
      }
    };

    // fetch current user
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // initialize and destroy the PerfectScrollbar plugin
  const onMainPanelRefChange = React.useCallback((node) => {
    setMainPanel(node);
    if (_.isNil(node)) {
      if (ps && navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    } else if (navigator.platform.indexOf('Win') > -1) {
      setPs(
        new PerfectScrollbar(node, {
          suppressScrollX: true,
          suppressScrollY: false,
        })
      );
      window.addEventListener('resize', resizeFunction);
      document.body.style.overflow = 'hidden';
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          <Sidebar
            routes={routes}
            logoText={'bin management'}
            logo={logo}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
            {...rest}
          />
          <div className={classes.mainPanel} ref={onMainPanelRefChange}>
            <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />
            {/* eslint-disable-next-line max-len */}
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes(user)}</div>
              </div>
            ) : (
              <div className={classes.map}>{switchRoutes(user)}</div>
            )}
            {getRoute() ? <Footer /> : null}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

export default connect(mapStateToProps, { fetchCurrentUser: _fetchCurrentUser })(Admin);
