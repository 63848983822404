/* eslint-disable operator-linebreak */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from '../../components/Grid';
import fetchAPI from '../../utils/fetchAPI';
import { getBooking } from '../../redux/selectors/booking';
import { getResource } from '../../redux/selectors/resources';
import { GST, RESOURCE_BINS, WASTE_TYPES_TEXT } from '../../redux/types';
import { formatCurrency, toDollarValue } from '../../utils/number';
import { grayColor } from '../../assets/jss/material-dashboard-react';

const useStyles = makeStyles({
  priceTotal: {
    marginBottom: 24,
  },
  lineContainer: {
    display: 'flex',
    width: '100%',
    height: 12,
    alignItems: 'center',
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: grayColor[3],
  },
  pullRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const PriceTotal = ({ booking, bins }) => {
  const classes = useStyles();
  const [bookingData, setBookingData] = React.useState({});
  const [priceData, setPriceData] = React.useState({});

  const getPriceData = async () => {
    const newPriceData = await fetchAPI('POST', {
      path: '/booking/calculate-cost',
      data: booking,
    });
    setPriceData(newPriceData);
    setBookingData(booking);
  };

  React.useEffect(() => {
    if (
      booking.duration !== bookingData.duration ||
      booking.binId !== bookingData.binId ||
      booking.postcode !== bookingData.postcode ||
      booking.mattresses !== bookingData.mattresses ||
      booking.tyres !== bookingData.tyres ||
      booking.councilPermitId !== bookingData.councilPermitId
    ) {
      getPriceData();
    }
  }, [booking]); // eslint-disable-line react-hooks/exhaustive-deps

  if (_.isEmpty(booking.binId)) return null;

  return (
    <Card className={classes.priceTotal}>
      <CardContent>
        <GridContainer>
          <GridItem xs={9}>
            {/* eslint-disable-next-line max-len */}
            {WASTE_TYPES_TEXT[booking.wasteType].title}&nbsp;
            {bins[booking.binId].volume}m<sup>3</sup> ({booking.duration} days)
          </GridItem>
          <GridItem xs={3} className={classes.pullRight}>
            {formatCurrency(toDollarValue(priceData.binPrice))}
          </GridItem>
        </GridContainer>

        {booking.mattresses > 0 ? (
          <GridContainer>
            <GridItem xs={9}>Mattresses x {booking.mattresses}</GridItem>
            <GridItem xs={3} className={classes.pullRight}>
              {formatCurrency(toDollarValue(priceData.mattresses))}
            </GridItem>
          </GridContainer>
        ) : null}

        {booking.tyres > 0 ? (
          <GridContainer>
            <GridItem xs={9}>Tyres x {booking.tyres}</GridItem>
            <GridItem xs={3} className={classes.pullRight}>
              {formatCurrency(toDollarValue(priceData.tyres))}
            </GridItem>
          </GridContainer>
        ) : null}

        {booking.councilPermitId && (
          <GridContainer>
            <GridItem xs={9}>Council Permit ({booking.duration} days)</GridItem>
            <GridItem xs={3} className={classes.pullRight}>
              {formatCurrency(toDollarValue(priceData.councilPermit))}
            </GridItem>
          </GridContainer>
        )}

        <GridContainer>
          <GridItem xs={9}>GST</GridItem>
          <GridItem xs={3} className={classes.pullRight}>
            {formatCurrency(toDollarValue(priceData.total * GST))}
          </GridItem>
        </GridContainer>

        <div className={classes.lineContainer}>
          <div className={classes.line} />
        </div>

        <GridContainer>
          <GridItem xs={9}>Total</GridItem>
          <GridItem xs={3} className={classes.pullRight}>
            {formatCurrency(toDollarValue(priceData.totalWithGST))}
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
};

PriceTotal.propTypes = {
  booking: PropTypes.object,
  bins: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  booking: getBooking(state),
  bins: getResource(state, RESOURCE_BINS),
});

export default connect(mapStateToProps)(PriceTotal);
