import _ from 'lodash';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Copyright from '../../components/Copyright';
import CustomerInfoForm from '../../components/BookingForm/CustomerInfoForm';
import PostcodeForm from '../../components/BookingForm/PostcodeForm';
import BookingForm from '../../components/BookingForm/BookingForm';
import BookingPayment from './BookingPayment';
import PriceTotal from './PriceTotal';
import { getCurrentUser } from '../../redux/selectors/currentUserId';
import { getBooking } from '../../redux/selectors/booking';
import { isCustomer } from '../../utils/authentication';

const { REACT_APP_FILES_URL } = process.env;

const IMAGES = [
  `${REACT_APP_FILES_URL}booking-image-1.jpg`,
  `${REACT_APP_FILES_URL}booking-image-2.jpg`,
  `${REACT_APP_FILES_URL}booking-image-3.jpg`,
  `${REACT_APP_FILES_URL}booking-image-4.jpg`,
  `${REACT_APP_FILES_URL}booking-image-5.jpg`,
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  gridItem: {
    height: '100%',
    overflow: 'auto',
  },
  image: {
    backgroundImage: `url(${_.sample(IMAGES)})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Postcode Availability', 'Booking Details', 'Customer Information', 'Review Booking'];

const getStepContent = (step, handleNext, handlePrev) => {
  switch (step) {
    case 0:
      return <PostcodeForm step={step} handleNext={handleNext} />;
    case 1:
      return <BookingForm handlePrev={handlePrev} step={step} handleNext={handleNext} />;
    case 2:
      return <CustomerInfoForm handleNext={handleNext} handlePrev={handlePrev} />;
    case 3:
      return <BookingPayment />;
    default:
      throw new Error('Unknown step');
  }
};

const Booking = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const currentUser = useSelector(getCurrentUser);
  const booking = useSelector(getBooking);
  const isAnonymous = !isCustomer(currentUser);
  const history = useHistory();

  const handleNext = () => setActiveStep(activeStep + 1);

  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      swal({
        title: 'Booking created successfully',
        icon: 'success',
      });
      if (isAnonymous) {
        window.location.href = 'https://www.billbinhire.com.au/';
      } else {
        history.push('/admin/tracking');
      }
    }

    if (query.get('canceled')) {
      swal({
        title: 'Error create booking',
        text: "Order canceled -- continue to shop around and checkout when you're ready.",
        icon: 'error',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container className={classes.root} justify="center" alignItems="stretch">
      <CssBaseline />
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <Box p={3}>
          <Card>
            <CardContent style={{ maxHeight: '100%', overflow: 'auto' }}>
              <Typography component="h1" variant="h4" align="center">
                Order
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {getStepContent(activeStep, handleNext, handleBack)}
            </CardContent>
            {isAnonymous && (
              <CardActions>
                <Box mx="auto">
                  <Copyright />
                </Box>
              </CardActions>
            )}
          </Card>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} className={classnames(classes.gridItem, classes.image)}>
        {booking.binId && (
          <Box p={3}>
            <Card>
              <CardContent>
                <PriceTotal />
              </CardContent>
            </Card>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Booking;
