import axios from 'axios';

const { REACT_APP_BASE_URL: BASE_URL } = process.env;
/**
 * @param {string} method
 * @param {string} path
 * @param {object} data
 * @param {object} params
 * @returns {object}
 */
const fetchAPI = async (method = 'GET', { path = '', data = {}, params = {}, options = {} }) => {
  const url = `${BASE_URL}${path}`;
  try {
    const { data: result } = await axios({
      method,
      url,
      data,
      params,
      withCredentials: true,
      ...options,
    });
    return result;
  } catch (e) {
    return { error: e };
  }
};

export default fetchAPI;
