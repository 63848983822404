import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../utils/date';

const PreviewShipping = ({ booking }) => {
  const { pickupDate, deliveryDate, duration } = booking;
  const pickup = formatDate(pickupDate);
  const delivery = formatDate(deliveryDate);

  return (
    <Fragment>
      <TextField
        label="Duration"
        InputProps={{
          type: 'number',
          default: 3,
          min: 3,
        }}
        fullWidth
        margin="normal"
        defaultValue={duration}
        disabled
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            label="Delivery Date"
            InputLabelProps={{ shrink: true }}
            type="text"
            fullWidth
            margin="normal"
            defaultValue={delivery}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Pickup Date"
            InputLabelProps={{ shrink: true }}
            type="text"
            fullWidth
            margin="normal"
            defaultValue={pickup}
            disabled
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

PreviewShipping.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default PreviewShipping;
